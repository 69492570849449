<template>
  <div class="modal-wrapper">
    <div class="modal">
      <span class="closeBtn" @click="cloneDiaogue"></span>
      <div class="tab-title">
        <div class="title-item" v-for='(item, index) in navList' :key="index" @click="changeTab(index)" :class="{'activeTab': index===num}">{{item}}</div>
      </div>
      <div>
        <div class="address" v-if="num === 1">
          <div v-if="prizeData && prizeData.name">
            <div class="nameAndphone">
              <p class="name">{{prizeData.name}}</p>
              <p class="phone">{{prizeData.phoneNumber}}</p>
            </div>
            <div align='left'>
              {{prizeData.address}}
            </div>
          </div>
          <div v-else>
            <img class="noprize" src="https://cos.youzijie.com/changAnCar/intention/noprize.png" alt="">
            <p>还没有填写收货地址哦~</p>
          </div>
        </div>
        <div class="prize" v-if="num === 0">
          <div v-if="prizeData">
            <img src="https://cos.youzijie.com/changAnCar/intention/ear-phone2.jpg" alt="">
            <p>UNI-K x OPPO Enco W31定制耳机</p>
          </div>
          <div v-else>
            <img class="noprize" src="https://cos.youzijie.com/changAnCar/intention/noprize.png" alt="">
            <p>还没有奖品哦~</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLotteryinfo } from '../../api'
export default {
  data() {
    return {
      navList:['我的奖品','收货地址'],
      num: 0,
      prizeData: ''
    }
  },
  created() {
    this.getData();
  },
  methods: {
    changeTab(i) {
      this.num = i
    },
    async getData() {
      const res = await getLotteryinfo();
      this.prizeData = res.data.lotteryInfo
      console.log(res);
    },
    cloneDiaogue() {
      this.$emit('cloneDiaogue');
    },
  }
}
</script>

<style scoped>
  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .46);
  }
  .modal {
    width: 599.12px;
    background: #fff;
    border-radius: 21.97px;
    margin: 300px auto;
    text-align: center;
    padding: 31px 29px 52px 29px;
    position: relative;
  }
  .tab-title {
    display: flex;
    margin-bottom: 52px;
  }
  .title-item {
    width: 50%;
    font-size: 26.36px;
  }
  .address {
    width: 100%;
    border: 1px solid #e8e8e8;
    border-radius: 7.32px;
    padding: 29px 23px;
  }
  .nameAndphone {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  .name {
    font-size: 24.93px;
    margin-right: 15px;
  }
  .phone {
    color: #9b9b9b;
    font-size: 20.53px;
  }
  .prize img{
    width: 210px;
    height: 210px;
    margin-bottom: 10px;
  }
  .prize p {
    padding-bottom: 25px;
  }
  .noprize {
    width: 194.82px;
    height: 194.82px;
  }
  .activeTab {
    font-size: 30.75px;
    color: #093f75;
  }
  .closeBtn {
  position: absolute;
  right: 20px;
  top: 26px;
  background: url('https://cos.youzijie.com/changAnCar/intention/delete.png');
  background-size: cover;
  width: 30px;
  height: 30px;
} 
</style>