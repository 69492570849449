<template>
  <div class="modal-wrapper">
    <div class="modal">
      <span class="closeBtn" @click="$emit('cloneDiaogue');"></span>
      <h4>活动规则</h4>
      <div class="ruleDetail">
        <p>1、抽奖活动时间：</p>
        <p>2021年3月22日——2021年4月30日</p>
        <p>2、领奖时间：</p>
        <p>2021年4月10日——2021年4月30日</p>
        <p>3、活动内容：</p>
        <p>活动期间，预约试驾留下个人资料，有机会获得由长安UNI-K为用户们准备的超值惊喜礼物。</p>
        <p>4、每人每个手机号码仅有一次抽奖机会；</p>
        <p>5、中奖后，请用户务必填写真实个人信息，主办方将在活动结束后30天内邮寄。</p>
        <h4>奖品设置</h4>
        <p>1、中奖用户需在活动页面中填写真实有效的个人信息，包括姓名、电话、邮寄地址，无需用户承担任何费用;</p>
        <p>2、活动中以用户最终提交的信息为准， 活动结束后，将不接受用户更改或填加信息;</p>
        <p>3.奖品以最终实物为准， 奖品一经发出，概不退换;</p>
        <p>特别说明:OPPO营销平台承诺对中奖用户的个人信息保密，在活动结束后用户信息将予以删除。在法律允许范围内，本活动解释权归长安汽车所有。</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .46);
  }
  .modal {
    width: 599.12px;
    height: auto;
    background: #fff;
    border-radius: 21.97px;
    margin: 113px auto;
    text-align: center;
    padding: 1px 54px 44px 54px;
    position: relative;
  }
  .img-box {
    padding: 47px 0 31px 0;
    display: flex;
    justify-content: center;
  }
  .modal h4 {
    margin: 45px 0 10px 0;
    font-size: 43.94px;
    font-weight: 400;
  }
  /* .modal p:nth-child(2) {
    font-size: 27.83px;
    font-weight: 400;
  } */
  button {
    width: 246.09px;
    height: 73.96px;
    border-radius: 36.99px;
    border: none;
    background: linear-gradient(to right, #014a94, #68a0d9);
    font-size: 35.15px;
    color: #fefefe;
    font-weight: blod;
    margin-top: 88px;
    outline: none;
  }
  .ruleDetail p{
    text-align: left;
    font-size: 23.43px;
  }
  .closeBtn {
  position: absolute;
  right: 30px;
  top: 26px;
  background: url('https://cos.youzijie.com/changAnCar/intention/delete.png');
  background-size: cover;
  width: 30px;
  height: 30px;
}
</style>