<template>
  <div class="modal-wrapper">
    <div class="modal">
      <div class="img-box">
        <img src="https://cos.youzijie.com/changAnCar/intention/illustration.png" alt="">
      </div>
      <p>很遗憾没有中奖</p>
      <p>感谢您的支持</p>
      <button @click="$emit('closeModal')">返回</button>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .46);
  }
  .modal {
    width: 599.12px;
    height: 596.79px;
    background: #fff;
    border-radius: 21.97px;
    margin: 300px auto;
    text-align: center;
  }
  .img-box {
    padding: 47px 0 31px 0;
    display: flex;
    justify-content: center;
  }
  img {
    width: 301px;
    height: 301px;
  }
  .modal p {
    font-size: 20px;
  }
  button {
    width: 487.79px;
    height: 73.96px;
    border-radius: 36.99px;
    border: none;
    background: linear-gradient(to right, #014a94, #68a0d9);
    font-size: 35.15px;
    color: #fefefe;
    font-weight: blod;
    margin-top: 25px;
    outline: none;
  }
</style>