<template>
  <div class="modal-wrapper">
    <div class="modal">
      <p>太遗憾啦～</p>
      <p>换个姿势，再试一次吧</p>
      <button @click="$emit('closeFail')">确定</button>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .46);
  }
  .modal {
    width: 599.12px;
    height: auto;
    background: #fff;
    border-radius: 21.97px;
    margin: 300px auto;
    text-align: center;
    padding: 40px 54px 44px 54px;
  }
  .img-box {
    padding: 47px 0 31px 0;
    display: flex;
    justify-content: center;
  }
  .modal p:first-child {
    margin: 97px 0 10px 0;
    font-size: 43.94px;
    font-weight: 400;
  }
  .modal p:nth-child(2) {
    font-size: 27.83px;
    font-weight: 400;
  }
  button {
    width: 246.09px;
    height: 73.96px;
    border-radius: 36.99px;
    border: none;
    background: linear-gradient(to right, #014a94, #68a0d9);
    font-size: 35.15px;
    color: #fefefe;
    font-weight: blod;
    margin-top: 88px;
    outline: none;
  }
</style>