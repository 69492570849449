import axios from 'axios';
import { uuid } from './utils'

export function postLottery() {
  return new Promise((resolve, reject) => {
    axios.post('/api/changan/car/purchase/lottery',{}, {headers: {requestId: uuid(32, 16)}}).then((res) => {
      resolve(res.data)
    }).catch(error => {
      reject(error)
    })
  })
}

export function postLotteryInfo(params) {
  return new Promise((resolve, reject) => {
    axios.post('/api/changan/car/purchase/lottery/info',params, {headers: {requestId: uuid(32, 16)}}).then((res) => {
      resolve(res.data)
    }).catch(error => {
      reject(error)
    })
  })
}

export function getLotteryinfo() {
  return new Promise((resolve, reject) => {
    axios.get('/api/changan/car/purchase/lottery/info', {headers: {requestId: uuid(32, 16)}}).then((res) => {
      resolve(res.data)
    }).catch(error => {
      reject(error)
    })
  })
}