<template>
  <div style="position: relative;">
    <div class="image-wrapper">
      <img src="https://cos.youzijie.com/changAnCar/intention/index_01.jpg" alt="">
      <img src="https://cos.youzijie.com/changAnCar/intention/luodi.jpg" alt="">
      <img src="https://cos.youzijie.com/changAnCar/intention/index_02.gif" alt="">
      <img src="https://cos.youzijie.com/changAnCar/intention/index_03.gif" alt="">
      <img src="https://cos.youzijie.com/changAnCar/intention/index_04.gif" alt="">
      <!-- <img src="https://cos.youzijie.com/changAnCar/intention/index_05.gif" alt=""> -->
      <img src="https://cos.youzijie.com/changAnCar/intention/luodi1.jpg" alt="">
    </div>
    <div class="game-btn">
      <button @click="$router.push('/game')">开始游戏</button>
    </div>
    <div class="btn-wrapper">
      <div class="buy-car" @click="show=true">预约体验</div>
      <a class="app-download" href="oaps://mk/dt?id=30274466">下载长安引力APP了解更多</a>
    </div>
    <div class="activeRule" @click="showActiveRule=true">活动规则</div>
    <div class="myPrize" @click="showPrize=true">我的奖品</div>
    <diaolue :show='show' :titleextra="false" titleinfo='请输入您的信息' @cloneDiaogue='close'></diaolue>
    <my-prize v-if="showPrize" @cloneDiaogue='showPrize=false'></my-prize>
    <active-rule v-if="showActiveRule" @cloneDiaogue='showActiveRule=false'></active-rule>
  </div>
</template>

<script>
// import axios from 'axios'
import diaolue from './dialogue';
import myPrize from '../tab/myPrize';
import activeRule from '../modal/activeRule'
export default {
  data() {
    return {
      show: false,
      showPrize: false,
      showActiveRule: false
    }
  },
  components: {
    diaolue,
    myPrize,
    activeRule,
  },
  created() {
  },
  methods: {
    async getSelectData() {
      
    },
    close() {
      this.show = false;
    }
  }
}
</script>

<style scoped>
  img {
    display: block;
    font-size: 0;
    width: 100%;
  }
  .btn-wrapper {
    position: fixed;
    left: 0;
    bottom: 10px;
    width: 100%;
  }
  .app-download, .buy-car {
    /* display: inline-block; */
    text-align: center;
    line-height: 102.54px;
    border-radius: 51.27px;
    font-size: 35.15px;
    width: 680px;
    margin: 0 auto;
    height: 102.54px;
  }
  .app-download {
    display: block;
    background: #fff;
    color: #2f70b3;
    text-decoration: none;
    margin-bottom: 10px;
  }
  .buy-car {
    background: linear-gradient(to right, #014a94, #68a0d9);
    color: #fff;
    margin-bottom: 18px;
  }
  .game-btn {
    text-align: center;
    line-height: 102.54px;
    border-radius: 51.27px;
    font-size: 35.15px;
    width: 100%;
    margin: 0 auto;
    height: 102.54px;
    position: absolute;
    left: 0;
    top: 1920px;
  }
  .game-btn button {
    border-radius: 51.27px;
    height: 102.54px;
    width: 380px;
    border: none;
    outline: none;
    color: #2f70b3;
    font-size: 35.15px;
    background: #fff;
  }
  .activeRule, .myPrize {
    position: absolute;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    width: 116.77px;
    height: 41.74px;
    background: rgba(255, 255,255, .5);
    border-radius: 20.87px 0 0 20.87px;
    right: 0;
    top: 1140px;
    text-align: center;
    font-size: 21.46px;
    line-height: 41.74px;
    border: 1px solid #fff;
    opacity: 0;
  }
  .myPrize {
    top: 1190px;
  }
</style>