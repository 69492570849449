<template>
  <div v-if="show" class="diaogue">
    <div class="diaogue-info">
      <span class="closeBtn" @click="cloneDiaogue"></span>
      <h3 >{{titleinfo}}</h3>
      <p class='titleextra' v-if="titleextra">完成信息填写 参与惊喜抽奖</p>
      <div class="info">
        <div class="info-form">
          <div class="info-label">姓名</div>
          <input placeholder="请输入姓名" type="text" v-model="name"/>
        </div>
        <div class="info-form">
          <div class="info-label">联系电话</div>
          <input placeholder="请输入手机号" type="text" v-model="phone"/>
        </div>
        <div class="info-form">
          <div class="info-label">所在省</div>
          <select placeholder="选择所在城市" type="text" @change="changeProvice" v-model='selectedProvice'>
            <option value="" disabled selected style="font-size:100px">选择省份</option>
            <option :value="item.province_name" v-for='(item,index) in provice' :key='index'>{{item.province_name}}</option>
          </select>
          <img class="arrow" src="https://cos.youzijie.com/changAnCar/intention/arrow.png" alt="">
        </div>
        <div class="info-form">
          <div class="info-label">所在市</div>
          <select placeholder="选择所在城市" type="text" @change="changeCity" v-model="selectedCity">
            <option value="" disabled selected>选择城市</option>
            <option :value="item.city_code" v-for='item in city' :key="item.city_code">{{item.city_name}}</option>
          </select>
          <img class="arrow" src="https://cos.youzijie.com/changAnCar/intention/arrow.png" alt="">
        </div>
        <div class="info-form">
          <div class="info-label">经销商</div>
          <select placeholder="选择所在城市" type="text" v-model="selectedDealer">
            <option value="" disabled selected>选择经销商</option>
            <option :value="item.dealer_id" v-for="item in dealer" :key="item.dealer_id">{{item.dealer_name}}</option>
          </select>
          <img class="arrow" src="https://cos.youzijie.com/changAnCar/intention/arrow.png" alt="">
        </div>
        <!-- <div class="info-form">
          <div class="info-label">近期购车计划</div>
          <span class="plan" :class="{'selectBuyCar': selectBuyCar}" @click="selectBuyCarClick">考虑购车</span>
          <span class="plan" :class="{'selectNotBuyCar': selectNotBuyCar}" @click="selectNotBuyCarClick">暂无计划</span>
        </div> -->
      </div>
      <div class="info-btn" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import selectData from "../../assets/data/changandealer1.json";
import { uuid } from '../../utils'
import axios from 'axios';
export default {
  props: {
    show: Boolean,
    titleinfo: String,
    titleextra: Boolean
  },
  data() {
    return {
      selectedProvice: '',
      city: '',
      selectedCity: '',
      dealer: '',
      selectedDealer: '',
      plan: 1,
      name: '',
      phone: '',
      selectBuyCar: true,
      selectNotBuyCar: false
    }
  },
  computed: {
    provice() {
      const arr = [];
      selectData.forEach((item) => {
        arr.push({ province_name: item.province_name });
      });
      return this.filterSelect(arr, 'province_name');
    },
    // city() {
    //   const arr = [];
    //   selectData.forEach((item) => {
    //     arr.push({ city_name: item.city_name, city_code: item.city_code });
    //   });
    //   return this.filterSelect(arr, 'city_name');
    // }
  },
  created() {
    console.log(selectData);
  },
  methods: {
    changeProvice() {
      this.city = this.filterSelect(selectData.filter(item => item.province_name === this.selectedProvice), 'city_name');
    },
    changeCity() {
      this.dealer = selectData.filter(item => item.city_code === this.selectedCity)
    },
    async submit() {
      const params = {name: this.name, phone: this.phone, cityCode: this.selectedCity, dealerId: this.selectedDealer, plan: this.plan}
      const data = await axios.post('/api/changan/car/purchase/intention', params, {headers: {requestId: uuid(32, 16)}});
      if (data.data.retCode === 0) {
        if (this.$route.name === 'game') {
          this.$router.push('/lottery');
        } else {
          this.$alert('提交成功');
        }
        this.$emit('cloneDiaogue');
        this.resetForm();
      } else {
        this.$alert(data.data.msg)
      }
      console.log(data);
      console.log(data);
    },
    filterSelect(arr, name) {
      const result = [];
      var obj = {};
      for (var i = 0; i < arr.length; i++) {
        if (!obj[arr[i][name]]) {
          result.push(arr[i]);
          obj[arr[i][name]] = true;
        }
      }
      return result;
    },
    cloneDiaogue() {
      this.resetForm();
      this.$emit('cloneDiaogue');
    },
    selectBuyCarClick() {
      console.log('ff');
      console.log(this.plan);
      this.plan = 1;
      this.selectBuyCar = true;
      this.selectNotBuyCar = false
    },
    selectNotBuyCarClick() {
      this.plan = 2;
      this.selectBuyCar = false;
      this.selectNotBuyCar = true
    },
    resetForm() {
      this.selectedCity = '';
      this.selectedDealer = '';
      this.selectedProvice = '';
      this.plan = 1;
      this.name = '';
      this.phone = '';
    }
  }
};
</script>

<style scoped>
.diaogue {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
}
.titleextra {
  text-align: center;
  font-size: 29.57px;
  color: #17224c;
  margin-top: -30px;
}
.diaogue-info {
  box-sizing: border-box;
  background: #fff;
  padding: 44px 50px;
  border-radius: 11px;
  width: 663.57px;
  position: relative;
}
.closeBtn {
  position: absolute;
  right: 60px;
  top: 26px;
  background: url('https://cos.youzijie.com/changAnCar/intention/delete.png');
  background-size: cover;
  width: 30px;
  height: 30px;
}
h3 {
  text-align: center;
  font-size: 40.31px;
  color: #17224c;
  margin: 0;
  margin-bottom: 33px;
}
.info-label {
  display: inline-block;
  width: 158px;
  margin-right: 22px;
}
.info-form {
  padding: 30px 0;
  border-bottom: 1px solid #ddd;
  font-size: 28px;
  position: relative;
}
.arrow {
  width: 20px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 36px;
}
.info-form input {
  border: none;
  outline: none;
  width: calc(100% - 188px);
}
.info-form select {
  border: none;
  overflow: hidden;
  width: calc(100% - 188px);
  outline: none;
  appearance:none;
  -moz-appearance:none;
  -webkit-appearance:none;
  background:none;
}
.info-form .plan {
  font-size: 12px;
  background: #ededed;
  padding: 6px 14px;
  border-radius: 2.2px;
  margin-right: 20px;
}
.info-btn {
  width: calc(100%);
  background: linear-gradient(to right, #3d4a8c, #7484c9);
  height: 74px;
  color: #fff;
  text-align: center;
  line-height: 74px;
  border-radius: 36.98px;
  margin-top: 44px;
  font-size: 35.15px;
}
.selectBuyCar {
  background: url('https://cos.youzijie.com/changAnCar/intention/gou.png') bottom right / 16px 16px no-repeat, #F2F5FF !important;
  border: 1px solid #6C82CF;
  color: #475597;
  border-radius: 2.2px;
}
.selectNotBuyCar {
  background: url('https://cos.youzijie.com/changAnCar/intention/gou.png') bottom right / 16px 16px no-repeat, #F2F5FF !important;
  border: 1px solid #6C82CF;
  color: #475597;
  border-radius: 2.2px;
}
select {
  color: #000;
  font-size: 24px;
}
select option[disabled] {
  color: red;
}
</style>