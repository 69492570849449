<template>
  <div class="modal-wrapper">
    <div class="modal">
      <div class="modal-content">
        <p>太棒啦</p>
        <p>恭喜你完成了挑战～</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .modal {
    width: 458.5px;
    height: 273.13px;
    background: rgba(255, 255, 255, .86);
    border-radius: 21.97px;
    margin: 510px auto;
    text-align: center;
  }
  .modal-content {
    padding: 52px 0 0 0;
  }
  .modal p:first-child {
    margin-bottom: 0px;
    font-size:85.42px;
    font-weight: 400;
  }
  .modal p:nth-child(2) {
    font-size: 27.6px;
  }
</style>