<template>
  <div class="modal-wrapper">
    <div class="modal">
      <span class="closeBtn" @click="cloneDiaogue"></span>
      <h2>中奖啦</h2>
      <div class="prize-item">
        <img src="https://cos.youzijie.com/changAnCar/intention/prize-item.png" alt="">
      </div>
      <div class="img-box">
        <img src="https://cos.youzijie.com/changAnCar/intention/ear-phone2.jpg" alt="" />
      </div>
      <div class="info">
        <div class="info-form">
          <div class="info-label">姓名</div>
          <input placeholder="请输入姓名" type="text" v-model="name" />
        </div>
        <div class="info-form">
          <div class="info-label">联系电话</div>
          <input placeholder="请输入手机号" type="text" v-model="phone" />
        </div>
        <div class="info-form" style="display:flex;">
          <div class="info-label">城市</div>
          <div class="selectCity" @click="show=true">{{address}}</div>
          <!-- <select
            placeholder="选择所在城市"
            type="text"
            @change="changeProvice"
            v-model="selectedProvice"
          >
            <option value="" disabled selected>选择省份</option>
            <option
              :value="item.province_name"
              v-for="(item, index) in provice"
              :key="index"
            >
              {{ item.province_name }}
            </option>
          </select> -->
          <img
            class="arrow"
            src="https://cos.youzijie.com/changAnCar/intention/arrow.png"
            alt=""
          />
        </div>
        <div class="info-form">
          <div class="info-label">详细收货地址</div>
          <textarea type="text" placeholder="请输入街道、门牌号等" v-model="addressDetail"></textarea>
        </div>
        <div class="tips">* 请核实收货地址无误，确认后不支持修改</div>
        <!-- <div class="info-form">
          <div class="info-label">近期购车计划</div>
          <span class="plan" :class="{'selectBuyCar': selectBuyCar}" @click="selectBuyCarClick">考虑购车</span>
          <span class="plan" :class="{'selectNotBuyCar': selectNotBuyCar}" @click="selectNotBuyCarClick">暂无计划</span>
        </div> -->
      </div>
      <button @click="submitForm" v-if="showBottom">确认收货地址</button>
      <button class="disahleBtn" disabled v-else>确认收货地址</button>
      <van-popup v-model="show" position="bottom" ><van-area title="标题" :area-list="areaList" @confirm='selectArea' @cancel='show=false'/></van-popup>
    </div>
  </div>
</template>

<script>
import { Area } from 'vant';
import { Popup } from 'vant';
import { areaList } from '../../static';
import {postLotteryInfo} from '../../api'
export default {
  data() {
    return {
      show: false,
      areaList,
      name: '',
      phone: '',
      address: '请选择城市',
      addressDetail: '',
    };
  },
  components: {
    'van-area': Area,
    'van-popup': Popup
  },
  computed: {
    showBottom() {
      return this.name && this.phone && this.address && this.addressDetail
    }
  },
  methods: {
    selectArea(e) {
      console.log(e);
      this.address = `${e[0].name} ${e[1].name} ${e[2].name}`;
      this.show = false;
    },
    async submitForm() {
      const params = {name: this.name, phoneNumber: this.phone, address: this.address + this.addressDetail};
      localStorage.userinfo = JSON.stringify(params);
      const res = await postLotteryInfo(params);
      if (res.errCode === 0) {
        console.log(123);
        this.$emit('closeAddr');
      }
      console.log(res);
    },
    cloneDiaogue() {
      this.$emit('cloneDiaogue');
    },
  }
};
</script>

<style scoped>
.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.46);
}
.modal {
  width: 599.12px;
  height: auto;
  background: #fff;
  border-radius: 21.97px;
  margin: 60px auto;
  text-align: center;
  padding: 40px 54px 28px 54px;
  position: relative;
}
.img-box {
  padding: 47px 0 31px 0;
  display: flex;
  justify-content: center;
}
.prize-item img {
  width: 492px;
  height: 67px;
}
.img-box img {
  width: 196px;
  height: 196px;
}
h2 {
  font-size: 69.53px;
  margin: 0;
  margin-bottom: 20px;
}
button {
  width: 487.79px;
  height: 73.96px;
  border-radius: 36.99px;
  border: none;
  background: linear-gradient(to right, #014a94, #68a0d9);
  font-size: 35.15px;
  color: #fefefe;
  font-weight: blod;
  margin-top: 25px;
  outline: none;
}
.disahleBtn {
  background: #8c949d;
}
.info {
  /* padding: 0 54px; */
  text-align: left;
}


.info-label {
  display: inline-block;
  width: 188px;
  margin-right: 22px;
}
.info-form {
  padding: 30px 0;
  border-bottom: 1px solid #ddd;
  font-size: 28px;
  position: relative;
  display: flex;
  align-items: center;
}
.info-form:last-child {
  align-items:flex-start;
}
.arrow {
  width: 20px;
  height: 30px;
  position: absolute;
  right: 10px;
  top: 36px;
}
.info-form input, textarea{
  border: none;
  outline: none;
  width: calc(100% - 218px);
}
.info-form .selectCity {
  display: inline-block;
  border: none;
  overflow: hidden;
  width: calc(100% - 218px);
  outline: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
}
.info-form .plan {
  font-size: 12px;
  background: #ededed;
  padding: 6px 14px;
  border-radius: 2.2px;
  margin-right: 20px;
}
.info-btn {
  width: calc(100%);
  background: linear-gradient(to right, #3d4a8c, #7484c9);
  height: 74px;
  color: #fff;
  text-align: center;
  line-height: 74px;
  border-radius: 36.98px;
  margin-top: 44px;
}
.tips {
  font-size: 17.57px;
  color: #d66666;
  margin-top: 14px;
}
.closeBtn {
  position: absolute;
  right: 60px;
  top: 26px;
  background: url('https://cos.youzijie.com/changAnCar/intention/delete.png');
  background-size: cover;
  width: 30px;
  height: 30px;
}
</style>