<template>
  <div class="lottery-content">
    <button @click="handleLottery">
      点击抽奖
    </button>
    <index v-if="showNoPrize" @closeModal='closeModal'></index>
    <adrress v-if="showAddress" @closeAddr='closeAddr' @cloneDiaogue='showAddress=false'></adrress>
    <my-prize v-if="showMyprize" @cloneDiaogue='showMyprize=false;'></my-prize>
  </div>
</template>

<script>
import { postLottery } from '../../api.js';
import index from '../modal/index'
import adrress from '../modal/adrress.vue'
import myPrize from '../tab/myPrize'
export default {
  data() {
    return {
      showNoPrize: false,
      showAddress: false,
      showMyprize: false
    }
  },
  components: {
    index,
    adrress,
    myPrize
  },
  methods: {
    async handleLottery() {
      const res = await postLottery();
      if (res.errCode === 0) {
        console.log(123);
        this.showAddress = true
      } else if (res.errCode === 10001) {
        this.$alert('您已经抽过奖了');
      } else {
        this.showNoPrize = true;
      }
      console.log(res);
    },
    closeAddr() {
      this.showAddress = false;
      this.showMyprize = true;
    },
    closeModal() {
      this.showNoPrize=false;
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
  .lottery-content {
    width: 100vw;
    height: 100vh;
    background: url('https://cos.youzijie.com/changAnCar/intention/lottery.jpg') left top / cover;
    text-align: center;
  }
  button {
    width: 504.64px;
    height: 102.52px;
    border-radius: 51.27px;
    background: linear-gradient(to right, #f4c770, #f0cf98), #eecb94;
    border: none;
    outline: none;
    font-size: 49.79px;
    color: #76571b;
    margin: 1166px auto 0 auto;
  }
</style>