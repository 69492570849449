<template>
  <div ref="imgArea" class="img-area">
    <div
      v-for="(item, index) in 9"
      :key="item"
      :class="[
        'img-cell',
        'img-cell-' + index,
        index === activeImgIndex ? 'img-active' : '',
      ]"
      :style="{ backgroundImage: 'url(' + imgSrc + ')' }"
      :ref="'piece' + index"
      @click="exchange($event, index)"
    ></div>
  </div>
</template>

<script>
// 洗牌
const shuffle = (arr) => {
  for (let i = 0, len = arr.length; i < len - 1; i++) {
    let index = parseInt(Math.random() * (len - i));
    let temp = arr[index];
    arr[index] = arr[len - i - 1];
    arr[len - i - 1] = temp;
  }
  return arr;
};
export default {
  props: {
    imgName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      playing: false,
      imgArea: null,
      imgSrc: null,
      randomImgs: [],
      activeImgIndex: -1,
    };
  },
	mounted() {
		this.initImg();
	},
  methods: {
		initImg() {
			this.imgSrc = `https://cos.youzijie.com/changAnCar/intention/${this.imgName}.jpg`
		},
    randomImg() {
      const randomArr = shuffle([0, 1, 2, 3, 4, 5, 6, 7, 8]);
      this.$refs.imgArea.children.forEach((el, index) => {
        el.index = randomArr[index];
        el.style.left = (randomArr[index] % 3) * 24 + "vw";
        el.style.top = Math.floor(randomArr[index] / 3) * 24 + "vw";
      });
      this.randomImgs = randomArr;
      this.playing = true;
    },
    exchange(e, index) {
      if (!this.playing) {
        return;
      }
      const preImg = this.$refs.imgArea.children[this.activeImgIndex];
      const curImg = e.target;
      if (this.activeImgIndex > -1) {
        if (index === this.activeImgIndex) {
          this.activeImgIndex = -1;
          return;
        }
        const tempTop = preImg.style.top;
        const tempLeft = preImg.style.left;
        const tempIndex = preImg.index;
        preImg.style.top = curImg.style.top;
        preImg.style.left = curImg.style.left;
        preImg.index = curImg.index;
        curImg.style.top = tempTop;
        curImg.style.left = tempLeft;
        curImg.index = tempIndex;
        this.activeImgIndex = -1;
        if (
          Object.values(this.$refs.imgArea.children).every(
            (el, index) => el.index === index
          )
        ) {
          this.$emit("isDone");
        }
        return;
      }
      this.activeImgIndex = index;
    },
  },
};
</script>

<style scoped>
.img-area {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 370px auto 121px auto;
  width: 540px;
  height: 540px;
  /* border: 4px solid #353c43; */
}
.img-cell {
  width: 180px;
  height: 180px;
  background-size: 540px;
  box-shadow: 0px 0px 0px 4px #353c43 inset;
  border: none;
  transition: left 0.3s ease-in-out, top 0.3s ease-in-out;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
}

.img-cell-0 {
  background-position: 0 0;
}
.img-cell-1 {
  background-position: -180px 0;
  top: 0;
  left: 180px;
}
.img-cell-2 {
  background-position: -360px 0;
  top: 0;
  left: 360px;
}
.img-cell-3 {
  background-position: 0 -180px;
  top: 180px;
  left: 0;
}
.img-cell-4 {
  background-position: -180px -180px;
  top: 180px;
  left: 180px;
}
.img-cell-5 {
  background-position: -360px -180px;
  top: 180px;
  left: 360px;
}
.img-cell-6 {
  background-position: 0 -360px;
  top: 360px;
  left: 0;
}
.img-cell-7 {
  background-position: -180px -360px;
  top: 360px;
  left: 180px;
}
.img-cell-8 {
  background-position: -360px -360px;
  top: 360px;
  left: 360px;
}
.img-active {
  box-shadow: 0px 0px 3px 1px red inset;
}
</style>
