import Vue from 'vue';
import Router from 'vue-router';
import Index from '../components/Index/Index.vue'
import game from '../components/Jigsaw/index.vue'
import lottery from '../components/Jigsaw/lottery.vue'
Vue.use(Router);
export default new Router({
	routes:[
    {path: '/', component: Index},
    {path: '/game',name: 'game', component: game},
    {path: '/lottery', component: lottery},
  ]
})