import Vue from 'vue'
import App from './App.vue'
import router from './roeuter'
import './reset.css'
import {Alert} from 'wc-messagebox'
import 'wc-messagebox/style.css'
Vue.use(Alert)
Vue.config.productionTip = false

router.afterEach(()=>{
  window.scrollTo(0,0)
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
