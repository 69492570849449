<template>
  <div class="jigsaw-wrapper">
    <!-- <div class="rule">活动规则</div>
    <div class="prize">我的奖品</div> -->
    <puzzle ref="puzzleRef" :imgName="picName" @isDone="isDone"></puzzle>
    <div class="countdown">
      <!-- <van-icon class="icon" name="clock-o" /> -->
      <!-- <span class="text">在{{ countdown }}秒内完成拼图</span> -->
    </div>
    <button
      :disabled="startBtndisable"
      class="start-btn"
      @click="startGame"
      v-if="!startBtndisable"
    >
      开始游戏
    </button>
    <button
      :disabled="startBtndisable"
      class="num-btn"
      @click="startGame"
      v-else
    >
      00 : {{countdown}}
    </button>
    <modal v-if="false"></modal>
    <adrress v-if="false"></adrress>
    <fail v-if="showFail" @closeFail='showFail=false'></fail>
    <complete v-if="showComplete"></complete>
    <myprize v-if="showPrize" @cloneDiaogue='showPrize=false'></myprize>
    <index v-if="showIndex" @closeModal='closeModal'></index>
    <diaolue titleinfo='距离抽奖只差一步啦' :titleextra='true' :show="showForm" @cloneDiaogue='showForm=false'></diaolue>
    <div class="guide" v-if="showGuide">
      <div class="guide-content">
        <img src="https://cos.youzijie.com/changAnCar/intention/rules.gif" alt="">
        <button @click="showGuide=false">dianji</button>
      </div>
    </div>
  </div>
</template>

<script>
import Puzzle from "./Puzzle.vue";
import modal from '../modal/index.vue';
import adrress from '../modal/adrress.vue'
import fail from '../modal/fail.vue'
import complete from '../modal/complete.vue'
import myprize from '../tab/myPrize'
import diaolue from '../Index/dialogue'
import index from '../modal/index'
import { getLotteryinfo } from '../../api'
const defaultTime = 60;
let time = defaultTime;
export default {
  name: "name",
  data() {
    return {
      countdown: `60`,
      timer: null,
      startBtndisable: false,
      showComplete: false,
      showFail: false,
      showForm: false,
      showGuide: true,
      showPrize: false,
      showIndex: false
    }
  },
  components: {
    Puzzle,
    modal,
    adrress,
    fail,
    complete,
    myprize,
    diaolue,
    index
  },
  mounted() {
    // this.isDone()
  },
  computed: {
    picName() {
      return `car-${Math.floor(Math.random()*3+1)}`
    }
  },
  methods: {
    async isDone() {
      clearInterval(this.timer);
      time = defaultTime;
      this.showComplete = true;
      console.log('isDone');
      console.log(localStorage.isSubmitForm);
      const res = await getLotteryinfo();
      console.log(res);
      if (res.errCode === 10004) {
        setTimeout(() => {
          this.showForm = true
        }, 1000)
      } else if(res.errCode === 10006) {
        setTimeout(() => {
          this.$router.push('/lottery')
        }, 1000)
      } else if(res.errCode === 10005) {
        setTimeout(() => {
          this.showIndex = true
        }, 1000)
      } else if (res.errCode === 10001 || res.errCode === 0) {
        setTimeout(() => {
          this.showPrize = true
        }, 1000)
      }
      // if (!localStorage.isSubmitForm) {
      //   setTimeout(() => {
      //     this.showForm = true
      //   }, 1000)
      // } else {
      //   setTimeout(() => {
      //     this.$router.push('/lottery')
      //   }, 1000)
      // }
    },
    startGame() {
      console.log(localStorage.isSubmitForm);
      // this.$router.push('/lottery')
      this.$refs.puzzleRef.randomImg();
      // console.log(puzzleRef.value)
      this.startBtndisable = true;
      this.timer = setInterval(() => {
          time--;
          this.countdown = `${time < 10 ? '0' + time : time}`;
          if (time === 0) {
              clearInterval(this.timer);
              time = defaultTime;
              this.startBtndisable = false;
              console.log('fail')
              this.showFail = true
          }
      }, 1000);
    },
    closeModal() {
      this.showIndex=false;
      this.$router.push('/')
    }
  }
};
</script>

<style scoped>
.jigsaw-wrapper {
  width: 100vw;
  height: 100vh;
  background: url('https://cos.youzijie.com/changAnCar/intention/bg.jpg') top center / cover;
  overflow:hidden;
  position: relative;
}
.countdown {
  text-align: center;
  font-size: 23.43px;
  font-weight:300;
}
.icon {
  vertical-align: middle;
  position: relative;
  top: -3px;
}
.text {
  display: inline-block;
  margin-left: 10px;
}
.start-btn {
  width: 504px;
  height: 102.52px;
  border-radius: 51.27px;
  display: block;
  margin: 15px auto;
  border: none;
  background: linear-gradient(to right, #014a94, #68a0d9);
  color: #fff;
  font-size: 49.79px;
  font-weight: 400;
}
.rule, .prize {
  position: absolute;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 116.77px;
  height: 41.74px;
  background: rgba(255, 255,255, .5);
  border-radius: 20.87px 0 0 20.87px;
  right: 0;
  top: 250px;
  text-align: center;
  font-size: 21.46px;
  line-height: 41.74px;
  border: 1px solid #fff;
}
.prize {
  top: 305px;
}
.num-btn {
  width: 504px;
  height: 102.52px;
  border-radius: 51.27px;
  display: block;
  margin: 15px auto;
  border: none;
  color: #014a94;
  font-size: 49.79px;
  font-weight: 400;
  background: rgba(255, 255, 255, .51);
}
.guide {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, .46);
}
.guide-content {
  position: relative;
}
.guide img {
  width: 100%;
  margin-top: 10vh;
}
.guide button {
  position: absolute;
  bottom: 55px;
  right: 0;
  border-radius: 51.27px;
  height: 122.54px;
  width: 100%;
  border: none;
  outline: none;
  color: #2f70b3;
  font-size: 35.15px;
  opacity: 0;
}
/* .jigsaw-wrapper::before {
  content:'活动规则';
  position: absolute;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 118.77px;
  height: 41.74px;
  background: #fff;
} */
</style>

